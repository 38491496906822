import '../../lib/babelPolyfill';
// eslint-disable-next-line
import Enigma from 'nbc.enigma';

// eslint-disable-next-line no-underscore-dangle
window.__nbcnd__ = window.__nbcnd__ || {};

// eslint-disable-next-line no-underscore-dangle
window.__nbcnd__.adQueue = window.__nbcnd__.adQueue || [];

function safeParse(what, fallback) {
  try {
    return JSON.parse(what);
  } catch (e) {
    return fallback;
  }
}

function asNumber(what) {
  if (typeof what === 'undefined') {
    return what;
  }

  const number = parseInt(what, 10);

  return Number.isNaN(number) ? 0 : number;
}

function asBoolean(what) {
  if (typeof what === 'undefined') {
    return what;
  }

  return what === 'true';
}

function createAd(el) {
  const initializedAd = Enigma.Ad.findByElement(el);
  if (initializedAd) {
    return initializedAd;
  }

  // offsetViewport is the percentage of viewport height. Example: If it's 100%
  // then the viewport height will be multiplied with 100% to give the new ad offset in Engima.
  // Currently, only boxinline ads have this value. We do this on the front end side
  // as opposed to inside Engima b/c we want to maintain Engima as an ad library separated
  // from Ramen
  const {
    slot,
    sizes,
    offset,
    targeting,
    renderOnView,
    refreshInterval,
    activeTab,
    offsetViewport,
  } = el.dataset;

  const ad = new Enigma.Ad(el, {
    slot,
    sizes: safeParse(sizes, []),
    targeting: safeParse(targeting, null),
    refreshInterval: refreshInterval === 'false' ? 0 : asNumber(refreshInterval),
    offset: asNumber(offset),
    renderOnView: asBoolean(renderOnView),
    offsetViewport: asNumber(offsetViewport),
    activeTab: asBoolean(activeTab),
  });

  return ad;
}

function runCallback(fn) {
  fn(Enigma, createAd);
}

if (!window.__enigmaconfig) { // eslint-disable-line no-underscore-dangle
  throw new Error('window.__enigmaconfig has not been set. set it or else.');
}

Enigma.configure(window.__enigmaconfig); // eslint-disable-line no-underscore-dangle

Array.prototype.forEach.call(document.querySelectorAll('[data-enigma]'), createAd);

window.__nbcnd__.adQueue.forEach(runCallback); // eslint-disable-line no-underscore-dangle
window.__nbcnd__.adQueue.length = 0; // eslint-disable-line no-underscore-dangle
window.__nbcnd__.adQueue.push = runCallback; // eslint-disable-line no-underscore-dangle
